<script>
import RPosterCard from './RPosterCard.vue'

export default {
  props: {
    posters: {
      type: Array,
      required: true
    }
  },
  components: { RPosterCard }
}
</script>

<template>
  <div
    class="container grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-7 masonry relative"
  >
    <!-- {{ posters.length }} -->
    <RPosterCard
      v-for="(poster, index) in posters"
      :key="poster._id"
      :isBig="!((index + 1) % 2)"
      :poster="poster"
    ></RPosterCard>
  </div>
</template>

<style lang="scss" scoped>
@media screen and(min-width: 1280px) {
  .masonry {
    grid-template-rows: 450px 102px 450px;
    grid-template-areas: 'a1 a2 a3' 'a1 a5 a3' 'a4 a5 a6';

    div:nth-child(1) {
      grid-area: a1;
    }

    div:nth-child(2) {
      grid-area: a2;
    }

    div:nth-child(3) {
      grid-area: a3;
    }

    div:nth-child(4) {
      grid-area: a4;
    }

    div:nth-child(5) {
      grid-area: a5;
    }

    div:nth-child(6) {
      grid-area: a6;
    }
  }
}
</style>
