<script setup lang="ts">
const { $get } = useAxios();

const { data: state, pending: isLoading } = await useAsyncData(
  "home page data",
  async () => {
    const now = Date.now();
    const [slides, posters, news] = await Promise.all([
      $get("/api/v3/slide?_sort=sort:asc"),
      $get(
        `/api/v3/poster?_limit=6&_sort=date:asc&date_gt=${new Date(
          new Date(now).setHours(new Date(now).getHours() - 1)
        ).toISOString()}`
      ),
      $get("/api/v3/news?_limit=20"),
    ]);
  
    return {
      slides,
      posters,
      news,
    };
  }
);
</script>

<template>
  <nuxt-layout name="default">
    <div v-if="!isLoading">
      <section class="pb-14">
        <r-carousel :slides="state.slides"></r-carousel>
      </section>
      <r-section title="Афиша" :link="{ to: '/posters', title: 'Вся афиша' }">
        <r-main-poster :posters="state.posters"></r-main-poster>
      </r-section>
      <r-news-main-page :news="state.news"></r-news-main-page>
    </div>
    <r-full-page-pro-loader v-else></r-full-page-pro-loader>
  </nuxt-layout>
</template>
