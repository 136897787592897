<script setup lang="ts">
import {
  getDate,
  getShortMonth,
  getDayOfWeek,
  getTime,
} from "~/modules/helpers/day";

const props = defineProps({
  slide: {
    type: Object,
    required: true,
  },
});

const { slide } = toRefs(props);

const isRef = computed(() => slide.value.kind === "ref");
const isLink = computed(() => slide.value.kind === "link");
const isCompilation = computed(() => slide.value.kind === "compilation");
const slideLink = computed(() => {
  if (isRef.value) {
    return `/theater/performs/${slide.value.poster.perform._id}`;
  } else if (isLink.value) {
    return slide.value.link;
  } else if (isCompilation.value) {
    return `/compilation/${slide.value.compilation._id}`;
  } else {
    return "";
  }
});
</script>

<template>
  <a
    :href="slideLink"
    class="relatve container rounded-lg overflow-hidden cursor-pointer w-full xl:w-320 h-57 md:h-92 xl:h-112 bg-cover bg-center grid grid-cols-12"
    :style="`background-image: url(https://ucarecdn.com/${slide.img.fileKey}/image.png)`"
  >
    <div
      v-if="isRef"
      class="col-start-10 xl:col-start-9 xl:pl-28 col-end-13 slide-background h-57 md:h-92 xl:h-112 flex"
    >
      <div class="w-full mt-auto h-20 md:h-55 xl:h-1/2 flex flex-row text-left">
        <div
          class="w-full xl:w-1/3 pl-5 pt-6 xs:pl-8 md:pl-14 lg:pl-32 xl:pl-0 xs:pt-5 md:pt-30 xl:pt-12 text-center"
        >
          <div
            class="mx-auto font-sans font-medium text-xl2 md:text-6xl uppercase text-white"
          >
            {{ getDate(slide.poster.date) }}
          </div>
          <div class="mx-auto w-8 md:w-12 bg-white h-0.5"></div>
          <div
            class="mx-auto font-sans font-medium text-lg md:text-2xl text-white"
          >
            {{ getShortMonth(slide.poster.date) }}
          </div>
        </div>
        <div class="w-2/3 hidden xl:flex flex-col space-y-1 pt-14 pb-4">
          <div class="text-white font-sans text-xs">
            {{ getTime(slide.poster.date) }},
            {{ getDayOfWeek(slide.poster.date) }}
          </div>
          <div class="text-white font-sans text-lg line-clamp-6 pr-4">
            {{ slide.poster.perform.snippet }}
          </div>
        </div>
      </div>
    </div>
  </a>
  <!-- :style="`background-image: url(https://ucarecdn.com/${slide.img.fileKey}/image.png)`" -->
</template>

<style scoped>
.slide-background {
  background-image: url("/slide-backgroud.svg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1279px) {
  .slide-background {
    background-position: 105% 106%;
    background-size: 150px 158px;
  }
}

@media screen and (max-width: 768px) {
  .slide-background {
    background-position: 105% 105%;
    background-size: 150px 158px;
  }
}
@media screen and (max-width: 767px) {
  .slide-background {
    background-position: 99% 110%;
    background-size: 110px 110px;
  }
}
@media screen and (max-width: 639px) {
  .slide-background {
    background-position: 105% 105%;
    background-size: 90px 90px;
  }
}
@media screen and (max-width: 394px) {
  .slide-background {
    background-position: 0% 105%;
    background-size: 90px 90px;
  }
}
</style>
