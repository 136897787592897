<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Autoplay } from "swiper";
import RSlide from './RSlide.vue'
console.log('Autoplay', Autoplay)
SwiperCore.use([Autoplay]);

export default {
  props: {
    slides: {
      type: Array,
      required: true
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    RSlide
  },
  data() {
    return {
      swiper: null,
      defaultOffset: 1340,
      autoplay: {
        delay: 6500,
        disableOnInteraction: false
      },
    }
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper
    },
    onSlideChange(swiper) {
      this.swiper = swiper
    },
    goToNextSlide() {
      this.swiper.slideNext()
    },
    goToPrevSlide() {
      this.swiper.slidePrev()
    }
  },
}
</script>

<template>
  <div class="relative w-full overflow-hidden">
    <!-- Slider main container -->
    <swiper
      ref="container"
      class="container relative swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events overflow-hidden xl:overflow-visible"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      :slides-per-view="1"
      :space-between="16"
      :speed="400"
      :autoplay="autoplay"
      :initialSlide="0"
      :breakpoints="{
        640: {
          spaceBetween: 60
        }
      }"
    >
      <swiper-slide v-for="slide in slides" class="px-4 xl:p-0">
        <r-slide :slide="slide"></r-slide>
      </swiper-slide>
      <div
        class="slide-next absolute -left-12 z-10 cursor-pointer"
        @click="goToPrevSlide"
        style="top: calc(50% - 18px)"
        v-if="swiper?.activeIndex !== 0"
      >
        <r-icon class="h-9 w-9" icon="arrow-left"></r-icon>
      </div>
      <div
        class="slide-next absolute -right-12 transform z-10 cursor-pointer"
        @click="goToNextSlide"
        style="top: calc(50% - 18px)"
        v-if="swiper?.activeIndex !== slides?.length - 1"
      >
        <r-icon class="h-9 w-9" icon="arrow-right"></r-icon>
      </div>
    </swiper>
  </div>
</template>
