<script>
import {
  getDate,
  getTime,
  getShortMonth,
  getDayOfWeek,
} from "~/modules/helpers/day";

import { onMounted, ref, toRefs, onErrorCaptured } from "vue";

import axios from "axios";

export default {
  props: {
    poster: {
      type: Object,
      required: true,
    },
    isBig: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { poster } = toRefs(props);

    const color = ref(null);
    const bgColor = ref(null);
    onMounted(async function getColorOrGlass() {
      try {
        const { data } = axios.get(
          `https://ucarecdn.com/${poster.value.perform.img.fileKey}/-/preview/-/main_colors/`
        );
        bgColor.value = `rgba(${data.main_colors[1].join(",")}, .5)`;
        color.value = `rgba(${data.main_colors[2].join(",")}, 1)`;
      } catch (error) {
        console.error(error);
      }

      return;
    });

    return {
      getDate,
      getTime,
      getShortMonth,
      getDayOfWeek,
      color,
      bgColor,
    };
  },
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    hoverOver() {
      this.hover = true;
    },
    hoverLeave() {
      this.hover = false;
    },
  },
};
</script>

<template>
  <div
    class="relative flex flex-col rounded-xl overflow-hidden bg-white pb-20 xl:pb-0"
  >
    <div
      class="rounded-xl overflow-hidden relative"
      :class="[isBig ? 'xl:h-56' : 'xl:h-72']"
    >
      <nuxt-link :to="`/theater/performs/${poster?.perform?._id}`">
        <img
          loading="lazy"
          class="w-full h-full object-cover"
          :src="`https://ucarecdn.com/${poster?.perform?.img?.fileKey}/-/format/auto/-/quality/smart/image.png`"
          :alt="poster?.perform?.img?.fileName"
        />
      </nuxt-link>
      <div
        v-if="poster?.kind === 'link' || poster?.isTouring"
        class="absolute bottom-3 right-3 text-sm bg-gray-dark rounded-md py-3/2 px-5 text-white font-semibold leading-none"
      >
        {{ poster.tourInfo.length ? poster.tourInfo : "Гастроли" }}
      </div>
      <div
        v-else-if="poster?.perform?.isPremiere"
        class="absolute bottom-3 right-3 text-sm bg-primary rounded-md py-3/2 px-5 text-white font-semibold leading-none"
      >
        Премьера
      </div>
      <div
        class="absolute top-3 right-3 flex transform rotate-154 rounded-lg overflow-hidden h-10 w-10 glass"
        :style="`color: ${color}; background-color: ${bgColor};`"
      ></div>
      <p
        class="text-lg font-semibold absolute top-5 right-3 w-10 text-center"
        :style="`color: ${color};`"
      >
        {{ poster?.perform?.age_limit }}
      </p>
    </div>
    <div class="pt-6 px-6/5 font-sans font-semibold text-akcent lowercase">
      {{
        `${getDate(poster?.date)} ${getShortMonth(poster?.date)} ${getTime(
          poster?.date
        )} ${getDayOfWeek(poster?.date)}`
      }}
    </div>
    <nuxt-link :to="`/theater/performs/${poster?.perform?._id}`">
      <div class="pt-2 px-6/5 font-sans font-semibold text-dark-gray text-xl">
        {{ poster?.perform?.name }}
      </div>
    </nuxt-link>
    <div class="pt-2 px-6/5">
      <p class="font-sans font-regular text-neutral text-lg">
        Автор:
        <b class="text-lg font-semibold">{{ poster?.perform?.author }}</b>
      </p>
      <p class="font-sans font-regular text-neutral text-lg pt-1/2">
        Режиссер:
        <b class="text-lg font-semibold">{{ poster?.perform?.producer }}</b>
      </p>
    </div>
    <p
      class="pt-5/4 px-6/5 font-sans font-regular text-neutral text-lg"
      :class="[isBig ? 'hidden' : 'line-clamp-3']"
    >
      {{ poster?.perform?.snippet }}
    </p>

    <nuxt-link :to="`/theater/halls/${poster?.perform?.hall?.to}`">
      <div class="absolute right-0 bottom-0 z-10 placeholder">
        <r-icon
          :icon="poster?.perform?.hall?.icon_rounded"
          @mouseover="hoverOver"
          @mouseleave="hoverLeave"
          class="w-16 h-18 cursor-pointer placeholder"
        />
      </div>
    </nuxt-link>
    <div
      class="titleBlock absolute bottom-6 right-12 z-50 text-lato text-sm font-normal text-center text-neutral bg-white rounded-md"
      v-show="hover"
    >
      {{ poster?.perform?.hall?.name }}
    </div>
    <div class="absolute px-6/5 pb-5 left-0 right-0 bottom-0">
      <nuxt-link :to="`/buy/${poster?.perform?._id}/${poster?._id}`">
        <button
          :disabled="!poster?.isActive"
          class="text-center text-sm transition bg-primary duration-300 ease-out rounded-md font-semibold leading-none focus:outline-none py-10p px-5 text-white"
          :class="[
            poster?.isActive
              ? 'hover:bg-cherry cursor-pointer'
              : 'bg-opacity-20 cursor-default',
          ]"
        >
          {{ poster?.isActive ? "Купить билет" : "Билеты проданы" }}
        </button>
      </nuxt-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.glass {
  backdrop-filter: blur(30px) brightness(1.15) opacity(1);
}

.titleBlock {
  text-shadow: 0px 2px 4px #0000000a;
  padding: 5px 17px;
  box-shadow: 0px 2px 4px #0000000a;
}
</style>
